import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ScreenSizeService } from 'app/shared/services/screen-size.service';

@Component({
  selector: 'custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss']
})
export class CustomSelectComponent implements OnInit, OnChanges {
  @ViewChild('searchInput') ngSelect: NgSelectComponent;
  @Output() itemSelected = new EventEmitter();
  @Input() options: any[] = [];
  @Input() multiple = false;
  @Input() searchable = true;
  @Input() clearable;
  @Input() disabled = false;
  @Input() isRequired = false;
  @Input() defaultValue;
  @Input() bindLabel = 'Name';
  @Input() optionLabel = 'Name';
  @Input() showArrow = false;
  @Input() type: string;
  @Input() isOpen = false;
  @Input() isOkrKeyResult?: boolean;
  @Input() placeholder: string = '';
  isMobile: boolean;
  isCalander: boolean = false;
  selectedItem;
  showMandatory: boolean = false;

  constructor(public screenSizeService: ScreenSizeService) {
    this.screenSizeService.ScreenSize.subscribe((res: number) => {
      this.isMobile = res <= 991;
    });
  }

  ngOnInit(): void {
    if (this.defaultValue) {
      this.selectedItem = this.defaultValue;
    }
    if (this.options[4]?.click == 'YTD') {
      this.isCalander = true;
    }
    if (this.isOpen) {
      setTimeout(() => {
        this.ngSelect.open();
      }, 10);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isOpen) {
      setTimeout(() => {
        this.ngSelect.open();
      }, 10);
    }
    if (changes && changes.options) {
      if (
        changes.options.currentValue &&
        !changes.options.currentValue.length
      ) {
        this.selectedItem = null;
      }
    }
  }

  onChange(item) {
    if (item.Id) {
      if (item.Id === this.selectedItem?.Id) {
        setTimeout(() => {
          this.selectedItem = null;
          this.showMandatory = true;
          this.itemSelected.emit(null);
          this.ngSelect.blur();
        }, 10);
      }
    } else if (item.value && !this.isOkrKeyResult) {
      if (item.value === this.selectedItem?.value) {
        setTimeout(() => {
          this.selectedItem = null;
          this.showMandatory = true;
          this.itemSelected.emit(null);
          this.ngSelect.blur();
        }, 10);
      }
    }
  }

  selectionChange(e) {
    this.itemSelected.emit(e);
  }

  onClickTest($event) {
    $event.stopPropagation();
  }
}
